import { Card, Space, Table, Tag } from 'antd';
import { Helmet } from 'react-helmet';
import { GetUserStatus } from '../services/users';
import { useEffect, useRef, useState } from 'react';

function MakeDataSource(val: any[]) {
    var out: any[] = []
    if (val.length > 0) {
        val.forEach((val, index) => {
            out.push({
                key: index,
                nickname: val.nickname,
                status: val.status,
                is_online: val.is_online ? "Online" : "Offline"
            })
        })
    }
    out.sort((a, b) => a.status > b.status ? 1 : -1)
    return out
}


function ViewDasboard() {
    const [datasource, setDatasource] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const ref = useRef({ userstatus: true })

    useEffect(() => {
        const load = async () => {
            if (ref.current["userstatus"]) {
                const userStatus: any = await GetUserStatus()
                if (userStatus) {
                    setLoading(false)
                    return setDatasource(MakeDataSource(userStatus.data))
                }
            }
            ref.current["userstatus"] = false
        }
        load()
    }, [])

    return (
        <>
            <Helmet>
                <title>
                    Cinverse Website - Home
                </title>
            </Helmet>
            {/* <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Breadcrumb> */}
            <Space direction='vertical' size={0} style={{ display: 'flex' }}>
                <Card
                    style={{
                        backgroundImage: "url('/images/minecraft-bg.png')",
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundBlendMode: 'overlay',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        borderRadius: 0,
                    }}
                    size='default'
                    bordered={false}
                >
                    <div style={{
                        textAlign: 'center',
                        color: 'white',
                    }}>
                        <h1 style={{
                            fontSize: '35px',
                            margin: 0,
                            padding: 0,
                            lineHeight: '1.2',
                        }}>Cinverse Official Website</h1>
                        <h2 style={{
                            fontSize: '16px',
                            margin: 0,
                            padding: 0,
                            lineHeight: '1',
                        }}>
                            Minecraft Community In Cinverse
                        </h2>
                    </div>
                </Card >
                <Card style={{
                    textAlign: 'center',
                    backgroundColor: 'rgb(255, 255, 255)',
                    borderRadius: 0,
                }}
                    size='small'
                    bordered={false}
                >
                    <p style={{
                        marginTop: '25px',
                        fontSize: '14px',
                        lineHeight: '1.3',
                    }}>
                        Selamat datang di official website cinverse, dimana kamu bisa menemukan hal menarik di server ini.
                        Kamu juga dapat bergabung di server ini sebagai player loh. Yuk join sekarang!
                    </p>
                </Card>
                <Card style={{
                    backgroundColor: 'white',
                    minHeight: '20vh',
                    borderRadius: 0,
                }}
                    size='small'
                    bordered={false}
                >
                    <Space direction='vertical' size={'small'} style={{
                        width: '100%'
                    }}>
                        <Card title='Realtime Player Status'
                            size='small'
                            style={{
                                textAlign: 'left'
                            }}>
                            <Table
                                loading={loading}
                                dataSource={datasource}
                                columns={[
                                    {
                                        title: 'Nickname',
                                        dataIndex: 'nickname',
                                        key: 'nickname'
                                    },
                                    {
                                        title: 'Timestamp',
                                        dataIndex: 'status',
                                        key: 'status'
                                    },
                                    {
                                        title: 'Status',
                                        dataIndex: 'is_online',
                                        key: 'is_online',
                                        render: (_, record) => (
                                            <>
                                                <Tag color={record.is_online === "Online" ? 'green' : 'red'}>
                                                    {record.is_online.toUpperCase()}
                                                </Tag>
                                            </>
                                        ),
                                    }
                                ]}
                            />
                        </Card>
                    </Space>
                </Card>
                <Card style={{
                    textAlign: 'center',
                    backgroundColor: 'rgba(236, 236, 236, 1)',
                    minHeight: '20vh',
                    borderRadius: 0,
                }}
                    size='small'
                    bordered={false}
                >
                    <p>
                        Something Awesome...
                    </p>
                </Card>
                <Card style={{
                    textAlign: 'center',
                    backgroundColor: 'rgba(236, 236, 236, 1)',
                    minHeight: '20vh',
                    borderRadius: 0,
                }}
                    size='small'
                    bordered={false}
                >
                    <p>
                        Something Awesome...
                    </p>
                </Card>
            </Space>

        </>
    );
};

export { ViewDasboard }