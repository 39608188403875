import axios from "axios";

export const api = axios.create({
    baseURL: "https://api.cinverse.my.id",
    headers: {
        "Content-Type": "application/json"
    },
})

api.interceptors.request.use(request => {
    return request
}, error => {
    return Promise.reject(error)
})

api.interceptors.response.use(response => {
    return response
}, error => {
    return Promise.reject(error)
})